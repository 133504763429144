import React from 'react';
import * as PropTypes from 'prop-types';

import styled from 'styled-components';

const SubtitleElement = styled.div`
    margin-top: 35px;
    font-family: 'Renogare-Regular';
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    letter-spacing: -0.5px;
    color: white;
    @media screen and (max-width: 767px) {
        font-size: 44px;
        line-height: 48px;
        letter-spacing: -2.29px;
    }
`;

const typeMap = {
    subtitle: SubtitleElement,
};

const SubTitle = ({ children, subtitleType }) => {
    const Component = typeMap[subtitleType] || SubtitleElement;
    return <Component>{children}</Component>;
};

SubTitle.defaultProps = {
    subtitle: null,
};

SubTitle.propTypes = {
    children: PropTypes.node.isRequired,
    subtitleType: PropTypes.string,
};

export default SubTitle;
